<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
      <!-- Logo on the left side -->
      <router-link to="/" class="navbar-brand">
        <!-- Replace 'path-to-your-logo.png' with the actual path to your logo -->
        <img :src="require('@/assets/logo.png')" alt="Logo" class="navbar-logo">
      </router-link>

      <!-- Toggler for mobile view -->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Menu items and search form on the right side -->
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <router-link class="nav-link" to="/" active-class="active">Home</router-link>
          </li>          
          <li class="nav-item dropdown">
            <a class="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Make an appointment
            </a>            
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Activities
            </a>            
          </li>
          <!-- <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              CITY SELECTION
            </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><router-link class="dropdown-item" to="/">Salt Lake City Metro</router-link></li>
              </ul>
          </li> -->          
          <li class="nav-item">
            <router-link class="nav-link" to="/login" active-class="active">Login/Register</router-link>
          </li>
          <!-- <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Register
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><router-link class="dropdown-item" to="/register/customer">Customer Registration</router-link></li>
              <li><router-link class="dropdown-item" to="/register/service-provider">Service Provider Registration</router-link></li>
            </ul>
          </li> -->
          <li class="nav-item">
            <router-link class="nav-link" to="/contact" active-class="active">Contact</router-link>
          </li>
          <!-- Search bar -->
          <!-- <li class="nav-item">
            <form class="d-flex align-items-center">
              <div class="input-group">
                <input class="form-control input-search form-control-sm" type="search" placeholder="Search here.." aria-label="Search">
                <button class="btn btn-search btn-sm" type="submit">
                  <i class="bi bi-search"></i> 
                </button>
              </div>
            </form>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
}
</script>

<style scoped>
.navbar
  {
    height:115px;    
  }
.nav-link
  {
    text-transform: uppercase;
    color:#fff;
    font-weight: 700;  
    font-size:13px;
    line-height: 16px;
    /* font-family: "Roboto", Sans-serif; */
    margin-right: 18px;
  }
  .nav-link.active {
    background-color: #45bfde;
  }

  .dropdown-menu
    {
      background-color: #212529;   
    }
  .dropdown-menu .dropdown-item {    
    color:#fff !important;
  }
  .dropdown-menu .dropdown-item:hover {    
    background-color: #45bfde;
  }
  .dropdown-item:active {
    background-color: #45bfde; /* Blue color for active dropdown items */
    color:#fff;
  }

  .navbar-logo {
    height: 115px; /* Set the height */
    width: auto; /* Keep the aspect ratio */
  }

  .btn-search
  {
    background-color: #45bfde;
    color:#fff;
    border-radius: 0px !important;
  }
  .btn-search:hover
  {
    background-color: #45bfde;
    color:#fff;
  }
  .input-search
  {
    background-color: #45bfde;
    border-radius: 0px !important;
    width:160px !important;
    border:0px;
    color:#fff !important;
  }
  .input-search::placeholder
  {
    color:#fff !important;
  }
  .input-search:hover, .input-search:focus, .input-search:active
  {
    background-color: #45bfde;
  }
</style>
