<template>
    <div>
      <nav-bar></nav-bar>
      <h1>Contact Us</h1>
      <p>Have questions or feedback? Contact us here.</p>
      <!-- Contact form or information goes here -->
      <footer-bar></footer-bar>
    </div>
  </template>
  
  <script>
  import NavBar from '@/components/NavBar.vue';
  import FooterBar from '@/components/FooterBar.vue';
  
  export default {
    name: 'ContactUs',
    components: {
      NavBar,
      FooterBar
    }
  };
  </script>
  <style scoped>
  /* Add contact page-specific styles here */
  </style>
  