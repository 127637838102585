import { createRouter, createWebHistory } from 'vue-router';

// Importing views
import HomePage from '@/views/HomePage.vue';
import CategoriesAll from '@/views/CategoriesAll.vue';
import CitiesAll from '@/views/CitiesAll.vue';
import LoginRegister from '@/views/LoginRegister.vue';
import ContactUs from '@/views/ContactUs.vue';
import NewsDetail from '@/views/NewsDetail.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/categories',
    name: 'categories',
    component: CategoriesAll
  },
  {
    path: '/cities',
    name: 'cities',
    component: CitiesAll
  },
  {
    path: '/login',
    name: 'loginRegister',
    component: LoginRegister
  },
  {
    path: '/contact',
    name: 'ContactUs',
    component: ContactUs
  },  
  {
    path: '/news/:category/:year/:month/:day/:slug',
    name: 'NewsDetail',
    component: NewsDetail,
    props: true
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
